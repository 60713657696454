import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import PermalinkView from "./components/Pages/PermalinkView";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { playGroundThemes } from "./components/UI/static";

const router = (currentTheme, handleThemeChange) =>
  createBrowserRouter([
    {
      path: "/",
      element: (
        <App currentTheme={currentTheme} setCurrentTheme={handleThemeChange} />
      ),
    },
    {
      path: "/:gistId",
      element: (
        <PermalinkView
          theme={currentTheme}
          setCurrentTheme={handleThemeChange}
        />
      ),
    },
    {
      path: "/meow",
      element: (
        <iframe
          src="https://dino-game-vmr8.onrender.com"
          style={{ width: "100%", height: "100vh", border: "none", backgroundColor: "white" }}
          title="Lita Cat Runner"
        />
      ),
    },
  ]);

function Root() {
  const [currentTheme, setCurrentTheme] = useState(null);

  useEffect(() => {
    const themePreference = localStorage.getItem("theme");
    if (themePreference) {
      setCurrentTheme(playGroundThemes[themePreference]);
    } else {
      setCurrentTheme(playGroundThemes["light"]);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message.startsWith("ResizeObserver loop")) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div",
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay",
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  const handleThemeChange = (theme) => {
    setCurrentTheme(theme);
    localStorage.setItem("theme", theme.name);
  };

  if (!currentTheme) return null;

  return (
    <div
      style={{ backgroundColor: currentTheme.backgroundColor, height: "100vh" }}
    >
      <RouterProvider router={router(currentTheme, handleThemeChange)} />
    </div>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
);
